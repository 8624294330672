<template>
  <div class="c-app">
    <TheSidebar v-if="navigation"/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
    import {dataService} from "../_services";

export default {
  name: 'TheContainer',
  data() {
    return {
      nav:  [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Nástenka',
        to: '/dashboard',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Eshop']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Produkty',
        to: '/content/product',
        icon: 'cil-calculator'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Objednávky',
        to: '/orders',
        icon: 'cil-list'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Faktúry',
        to: '/invoices',
        icon: 'cil-envelope-closed'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Zákazníci',
        to: '/users',
        icon: 'cil-people'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Web']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Obsah',
        icon: 'cil-puzzle',
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Vzhľad',
        icon: 'cil-drop',
        items: [
        {
            name: 'Bloky',
            to: '/blocks'
          },
          {
            name: 'Menu',
            to: '/menu'
          },
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Nastavenia',
        icon: 'cil-settings',
        items: [

          {
            name: 'Slovníky',
            to: '/taxonomy'
          },
          {
            name: 'Šablóny',
            to: '/template'
          },
          {
            name: 'Nastavenia',
            to: '/webinfo'
          },
          {
            name: 'Kontá',
            to: '/roles'
          },
          {
            name: 'Typy obsahu',
            to: '/content-types'
          },{
            name: 'Formuláre',
            to: '/forms'
          },
          {
            name: 'SEO',
            to: '/seo'
          }
        ]
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Ostatné']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Fakturácia služby',
        to: '/invoices-services',
        icon: 'cil-cursor'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Partneri',
        to: '/partners',
        icon: 'cil-people'
      },

    ],
  }
],  navigation: JSON.parse(localStorage.getItem('ctypes'))
    }
  },
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  mounted() {
    dataService.axiosFetch("contenttypes").then(results => {
      this.contenttypes = results;
if(!localStorage.getItem('ctypes'))
{
 this.nav[0]._children[7].items = [];
this.contenttypes.forEach(obj => {
this.nav[0]._children[7].items.push( { name: obj.title, to: '/content/' + obj.slug })
});


localStorage.setItem('ctypes', JSON.stringify(this.nav));
this.navigation =  this.nav;
    }

});
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
