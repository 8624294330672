<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="65"
        viewBox="-50 0 456 104"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="25"
        viewBox="0 0 175 134"
      />
    </CSidebarBrand>
<template v-if="navigation">
    <CRenderFunction flat :content-to-render="navigation" />
  </template>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
export default {
  name: 'TheSidebar',

  data() {
            return {
                sidebarBackground: 'vue',
                contenttypes: [],
                taxonomies: [],
                searchQuery: '',
                collapsed: false,
                unitType: "",
                openKeys: [],
                fieldData: {},
                searchResults:[],
                loading:true,
                roles: [],
                paths: [],
                navigation: false,
                ctypes: [],
                selectedKeys: []
            };
        },
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  },
  mounted(){

    this.selectedKeys = [this.$router.currentRoute.name];
            const loggedIn = localStorage.getItem('user');
            const user = JSON.parse(loggedIn);
            this.navigation = JSON.parse(localStorage.getItem('ctypes'));
            console.log(this.navigation);
            this.roles = user.data.type_id;
            this.paths = user.data.paths;
            this.unitType = user.data.unitType;
            this.ctypes = user.data.ctypes;
  },
  methods: {

  },


}
</script>
